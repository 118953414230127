.agenda {
  background: linear-gradient(180deg, #f5f0e8 80%, #1c1d22 25%);
  padding-top: 50px;
  position: relative;
  justify-content: space-between;
  display: flex;
  padding: 0 50px;
  min-height: 300px;
}
.containerIphoneAgenda {
  margin-top: -90%;
}
.agendaDisplayNone {
  background: linear-gradient(180deg, #f5f0e8 80%, #1c1d22 25%);
  padding-top: 50px;
  position: relative;
  justify-content: space-between;
  display: flex;
  padding: 0 50px;
}
.containerTitle {
  padding-left: 50px;
  padding-right: 250px;
}
.titleAgenda {
  font-size: var(--title);
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #000000;
  padding-top: 50px;
  padding-bottom: 20px;
  font-style: normal;
  font-weight: 500;
}
.titleArtist {
  font-size: var(--subtitle);
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #000000;
  font-style: normal;
  font-weight: 500;
}
.iphoneAgenda {
  width: 300px;
}

@keyframes iphoneAgenda {
  0% {
    transform: translateX(180%);
  }
  100% {
    transform: translateX(0%);
  }
}

.animContainerIphoneAgenda {
  overflow: hidden;
  margin-top: -90%;
  -webkit-animation: iphoneAgenda 2s;
}

@media screen and (max-width: 1300px) {
  .iphoneAgenda {
    position: static;
  }
  .agenda {
    display: flex;
    flex-direction: row-reverse;
    padding: 50px;
    align-items: center;
  }
  .titleAgenda {
    padding-top: 0;
  }
  .animContainerIphoneAgenda {
    position: static;
  }
  .containerIphoneAgenda {
    position: static;
  }
  .containerTitle {
    padding-right: 0;
  }
}
@media screen and (max-width: 900px) {
  .containerTitle {
    padding-right: 0;
    width: 300px;
  }
  .containerText {
    padding-top: 30px;
    justify-content: flex-start;
  }
  .viewport-block-agenda{
    height: 500px;
    display: flex;
    align-items: flex-end;
  }
}

@media screen and (max-width: 700px) {
  .agenda {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 10px 0px 10px;
    min-height: 700px;
    justify-content: space-between;
  }
  .iphoneAgenda {
    position: static;
    width: 230px;
  }
  .titleArtist {
    font-size: var(--subtitleMobile);
  }
  .titleAgenda {
    padding-top: 0;
    font-size: var(--titleMobile);
  }
  .containerTitle {
    padding: 0 50px;
    text-align: center;
    width:auto;
  }
  .containerText {
    margin: 0 40px;
  }
  .containerTop{
    padding-left:0;
  }
  .titleLastPage{
    text-align: center;
  }
}
@media screen and (max-width: 500px) {
  .agenda {
    min-height: 830px;
  }
}
