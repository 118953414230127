.containerDiscover {
  padding: 50px 50px 0 50px;
  min-height: 500px;
  display: flex;
  justify-content: flex-start;
  background: linear-gradient(180deg, #1c1d22 80%, #f5f0e8 25%);

}
.imgIphoneDiscover {
  width: 300px;
  margin-right: 70px;
}
.containerText {
  display: flex;
  flex-direction: column;
  float: right;
  padding-top: 100px;
  width: 60%;
}
.textDiscover {
  font-size: var(--title);
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #ea974c;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 50px;
}
.textMedium {
  font-size: var(--subtitle);
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 5px;
}
.textReco {
  font-size: var(--subtitle);
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 5px;
}

@keyframes iphoneDiscover {
  0% {
    transform: translateX(-180%);
  }
  100% {
    transform: translateX(0%);
  }
}

.animContainerIphoneDiscover {
  -webkit-animation: iphoneDiscover 2s;
}

@media screen and (max-width: 1300px) {
  .agenda {
    padding: 0 50px 50px 50px;
  }
  .containerTitle {
    margin-bottom: 20%;
  }
  .containerDiscover {
    height: auto;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 50px 50px 0 50px;
    min-height: 700px;
  }
  .imgIphoneDiscover {
    margin-right: 0;
  }
  .containerText {
    justify-content: center;
    margin-right: 20px;
    margin-bottom: 25%;
    width: auto;
  }
  .animContainerIphoneDiscover {
    position: static;
  }
  .containerIphoneDiscover {
    position: static;
  }
  .containerTop {
    display: flex;
    flex-direction: row-reverse;
  }
}
@media screen and (max-width: 1300px) {
  .containerTop {
    padding-top: 0;
  }
}

@media screen and (max-width: 700px) {
  .containerDiscover {
    flex-direction: column;
    align-items: center;
    padding: 50px 10px;
    background: #1c1d22;
    min-height: 870px;
    justify-content: flex-end;
  }
  .containerText {
    order: 1;
    text-align: center;
  }
  .imgIphoneDiscover {
    order: 2;
    width: 230px;
  }
  .textDiscover {
    font-size: var(--titleMobile);
    margin-bottom: 30px;
  }
  .textMedium {
    font-size: var(--subtitleMobile);
    margin-bottom: 30px;
  }
  .textReco {
    font-size: var(--subtitleMobile);
  }
  .animContainerIphoneDiscover {
    margin-bottom: 20px;
  }
  .containerIphoneDiscover {
    margin-bottom: 20px;
  }
}
