.containerPicture{
    display:flex;
    justify-content:center;
    position: relative;
}
.pictureTastrTwo{
    width: 250px;
    position: absolute;
    bottom: -15%;
    left: 42%;
}