.footer {
  background-color: #1c1d22;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  width: 95vw;
}
.contentReview{
  width:40%;
}
.containerReview{
  width:40%;
}
.review {
  width: 40px;
}

.textFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.text {
  font-size: var(--subtitle);
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #fbf7f2;
  margin: 0 15px;
  font-style: normal;
  font-weight: 300;
  margin-left: 20px;
}
.textTastr {
  font-size: var(--minititle);
  line-height: 150%;
  letter-spacing: 0.01em;
  font-style: normal;
  font-weight: 100;
  color: #fbf7f2;
  margin-left: 15px;
  opacity: 0.4;
}

.containerMiddle {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.imgAppStoreFooter {
  width: 140px;
}
.imgGoogleStoreFooter {
  width: 140px;
  margin-left: 10px;
}
.imgFooter {
  width: 40%;
}
.containerImgFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media screen and (max-width:1225px){
  .containerImgFooter{
    flex-wrap:wrap;
  }
}
@media screen and (max-width: 809px){
  .imgAppStoreFooter{
    margin-bottom:10px;
  }
}
@media screen and (max-width: 700px) {
  .contentReview{
    width:auto;
  }
  .imgFooter {
    position: static;
    margin-bottom: 10px;
  }
  .footer {
    padding: 20px 0;
    flex-direction: column-reverse;
  }
  .textFooter {
    padding-top: 20px;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .textTastr {
    margin: 0 20px 20px 20px;
  }
  .text {
    margin: 0;
    margin-bottom: 10px;
  }
  .containerImgFooter{
    justify-content: center;
  }
  .imgAppStoreFooter {
    order: 3;
    margin-right: 0px;
  }
  .imgGoogleStoreFooter {
    margin-left: 0;
    order: 4;
  }
  .containerReview{
    width:auto;
  }
}
