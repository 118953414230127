.contentCgu {
  background: #f5f0e8;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
}
.logo {
  width: 200px;
}
.allTextCgu {
  width: 70%;
  padding: 100px 0;
}

.title {
  font-size: var(--title);
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #000000;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 40px;
}
.subtitle {
  font-size: var(--subtitle);
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #000000;
  font-style: normal;
  font-weight: 300;
  margin-bottom: 20px;
}
.textCgu {
  font-size: var(--minititleMobile);
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #000000;
  font-style: normal;
  font-weight: 300;
  margin-bottom: 20px;
  text-align:justify;
}

@media screen and (max-width: 700px) {
  .allTextCgu {
    width: 80%;
  }
}
