.App{
    background: #1c1d22;
    overflow: hidden;
}

.displayNone {
    display:none;
}

.displayWidth {
    width:360px;
    height:100px;
}