.lastpage {
  background-color: #1c1d22;
}
.containerTop {
  display: "flex";
  padding-bottom: 80px;
  min-height: 600px;
}
.iphoneLastPage {
  width: 280px;
  padding: 0 40px;
}
.titleLastPage {
  font-size: var(--title);
  line-height: 150%;
  letter-spacing: 0.01em;
  font-style: normal;
  font-weight: 500;
  padding-top: 40px;
  padding-bottom: 60px;
  color: #ea974c;
}
.textLastPage {
  font-size: var(--subtitle);
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #fbf7f2;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 5px;
}
.containerTextHeart {
  display: flex;
  align-items: center;
}
.textHeart {
  font-size: var(--subtitle);
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #fbf7f2;
  margin-right: 5px;
  font-style: normal;
  font-weight: 500;
}
.heart {
  width: 30px;
}
.containerPicture {
  display: "flex";
  justify-content: center;
  padding-top: 8vh;
  padding-bottom: 20px;
}
.textTrust {
  font-size: var(--title);
  line-height: 150%;
  letter-spacing: 0.01em;
  padding-bottom: 20px;
  font-style: normal;
  font-weight: 500;
}

.containerSlider {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-250px * 10));
    transform: translateX(calc(-250px * 10));
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-250px * 10));
    transform: translateX(calc(-250px * 10));
  }
}
.slider {
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 98vw;
}
.slider::before,
.slider::after {
  content: "";
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  -webkit-animation: scroll 25s linear infinite;
  animation: scroll 25s linear infinite;
  display: flex;
  width: calc(250px * 14);
}
.slider .slide {
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pictureTrust {
  width: 150px;
  margin: 20px;
}
.pictureTrustBelle {
  margin: 20px;
}
.pictureTrustMin {
  margin: 20px;
  width: 100px;
}

.containerTopLastPage {
  display: flex;
  flex-direction: column;
}

img.pictureLastPage {
  width: 40%;
  object-fit: scale-down;
  margin-top: 7%;
}

@keyframes iphoneLastPage {
  0% {
    transform: translateX(-180%);
  }
  100% {
    transform: translateX(0%);
  }
}

.animContainerIphoneLastPage {
  z-index: 1000;
  -webkit-animation: iphoneLastPage 2s;
}

@media screen and (max-width: 1220px) {
  .heart {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .containerPictureTrust {
    padding-bottom: 50px;
  }
  .containerTop {
    padding-top: 50px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
  }
  .pictureLastPage {
    display: none;
  }
  .containerTopLastPage {
    padding-left: 50px;
    width: 310px;
  }
}

@media screen and (max-width: 700px) {
  .lastpage {
    min-height: 1150px;
    display: flex;
    flex-direction: column;
  }
  .titleLastPage {
    font-size: var(--titleMobile);
    padding-top: 0;
  }
  .containerTopLastPage {
    padding: 20px 50px;
  }
  .containerTop {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 0;
    justify-content: flex-end;
  }
  .textLastPage {
    text-align: center;
    font-size: var(--subtitleMobile);
  }
  .containerTextHeart {
    justify-content: center;
  }
  .textHeart {
    text-align: center;
    font-size: var(--subtitleMobile);
  }
  .textTrust {
    text-align: center;
    font-size: var(--titleMobile);
  }
  .textTastr {
    text-align: center;
    font-size: var(--minititleMobile);
  }
  .iphoneLastPage {
    width: 230px;
    padding-bottom: 50px;
  }
}
