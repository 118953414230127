@import "../../css/fonts/primary_font.css";

.containerHome {
  background-color: #1c1d22;
  display: flex;
  justify-content: "space-around";
}
.containerLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  padding-top: 50px;
}
.containerLeftText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 300px;
}
.logo {
  width: 250px;
}
.hello {
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@keyframes iphoneOne {
  0% {
    top: -110%;
  }
  100% {
    top: 10%;
  }
}

.animContainerIphoneOne {
  position: absolute;
  right: 14vw;
  top: 10%;
  -webkit-animation: iphoneOne 2s;
  z-index: 10;
}

@keyframes iphoneTwo {
  0% {
    transform: translateX(180%);
  }
  100% {
    transform: translateX(0%);
  }
}

.animContainerIphoneTwo {
  -webkit-animation: iphoneTwo 2s;
}

.textDiscoverHome {
  font-style: normal;
  font-weight: 500;
  font-size: var(--title);
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.containerStore {
  margin-bottom: 50px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.imgAppStore {
  width: 200px;
  margin-right: 20px;
}
.imgGoogleStore {
  width: 200px;
}
.textSearch {
  color: #ea974c;
  font-size: var(--subtitle);
  letter-spacing: 1%;
  line-height: 150%;
  font-style: normal;
  font-weight: 500;
}

.containerRight {
  display: flex;
  position: relative;
  justify-content: flex-end;
  width: 45%;
  overflow: hidden;
  min-height: 650px;
  padding-right: 50px;
  padding-top: 50px;
}
.containerIphoneOne {
  position: absolute;
  right: 14vw;
  top: 10%;
}
.imgIphoneOne {
  width: 250px;
}

.imgIphoneTwo {
  width: 250px;
}

.pictureTastrOne {
  width: 250px;
  position: absolute;
  bottom: -15%;
  left: 42%;
  z-index: 10;
}

@media screen and (max-width: 1126px) {
  .imgAppStore {
    margin-bottom: 0;
  }
  .pictureTastrOne {
    display: none;
  }
}
@media only screen and (max-width: 1060px) and (min-width: 901px) {
  .containerLeftLogo {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 945px) and (min-width: 901px) {
  .imgAppStore {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 900px) {
  .containerLeftText {
    min-height: auto;
    padding-top: 50px;
  }
  .hello {
    align-items: center;
  }
  .containerLeft {
    padding-left: 0;
  }
  .containerRight {
    padding-right: 0;
    padding-top: 0;
  }
  .containerLeftLogo {
    margin-bottom: 30px;
    display: block;
  }
  .containerHome {
    flex-direction: column;
  }
  .containerLeft {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }
  .containerStore {
    justify-content: center;
  }
  .textDiscoverHome {
    text-align: center;
    width: 80%;
  }
  .textSearch {
    text-align: center;
  }
  .containerRight {
    width: 100%;
    justify-content: space-around;
    height: 600px;
  }
  .containerIphoneOne {
    position: static;
  }
  .imgAppStore {
    margin-bottom: 0;
    margin-right: 40px;
  }

  @keyframes iphoneOne {
    0% {
      transform: translateX(-180%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  .animContainerIphoneOne {
    position: static;
    -webkit-animation: iphoneOne 2s;
  }
}

@media screen and (max-width: 550px) {
  .containerHome {
    align-items: center;
    min-height: 1350px;
  }
  .containerRight {
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    height: auto;
  }
  .containerLeft {
    width: 80%;
  }
  .imgIphoneTwo {
    display: none;
  }
  .containerHome {
    height: auto;
  }
  .textDiscoverHome {
    font-size: var(--titleMobile);
    width: 100%;
  }
  .textSearch {
    font-size: var(--subtitleMobile);
  }
  .containerStore {
    justify-content: center;
  }
  .imgAppStore {
    margin-bottom: 0;
    margin-right: 30px;
    width: 200px;
  }
  .imgGoogleStore {
    width: 200px;
  }
}

@media screen and (max-width: 527px) {
  .imgAppStore {
    margin-right: 0;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 499px) {
  .imgAppStore {
    margin-bottom: 20px;
  }
}
