.titleReco {
  text-align: center;
  padding-top: 150px;
  font-size: var(--title);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 1%;
  padding-bottom: 40px;
}
.containerRecommandation {
  background-color: #f5f0e8;
}

.containerCard {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-bottom: 80px;
}

@media screen and (max-width: 900px) {
  .titleReco {
    font-size: var(--title);
    padding-top: 10vh;
    padding-bottom: 5vh;
  }
}
@media screen and (max-width: 700px){
  .containerRecommandation {
    background-color: #f5f0e8;
    min-height: 1300px;
  }
  .containerCard{
    padding-bottom:0;
  }
  .titleReco {
    font-size: var(--titleMobile);
  }
}
