.cardReco {
  background-color: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: 270px;
  padding: 20px 10px 30px 20px;
  min-height: 160px;
}

@keyframes cardReco {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.animCardReco {
  background-color: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: 270px;
  padding: 20px 10px 30px 20px;
  -webkit-animation: cardReco 2s;
  min-height: 160px;
}
.containerTop {
  display: flex;
  padding-bottom: 20px;
}
.pictureCard {
  width: 50px;
  margin-right: 12px;
}
.containerCardTop {
  display: flex;
  padding-bottom: 20px;
  flex: 1;
}
.pictureName {
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 1%;
  margin-bottom: 2px;
}
.pictureText {
  font-style: normal;
  font-weight: 399;
  font-size: 15.1254px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #000000;
}

@media screen and (max-width: 1199px) {
  .cardReco {
    margin-bottom: 50px;
  }
  .containerTop {
    padding: 0 0 0 50px;
  }
  .animCardReco{
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 550px) {
  .titleReco {
    font-size: var(--titleMobile);
    padding: 100px 40px 40px;
  }
}
