.containerPicture {
  display: flex;
  justify-content: center;
}
.pictureTastr {
  width: 250px;
  position: absolute;
  bottom: 5%;
  left: 42%;
  z-index: 10;
}


@media screen and (max-width: 1050px) {
  .pictureTastr {
    display: none;
  }
}
@media screen and (max-width: 634px) {
  .containerStore {
    justify-content: center;
  }
  .pictureTastr {
    left: 30%;
  }
  .containerText {
    margin: 0 50px;
  }
}

@media screen and (max-width: 550px) {
  .pictureTastr {
    display: none;
  }
}
