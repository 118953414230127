@font-face {
    font-family: 'Heebo';
    src: url('../fonts/primary_font/Heebo-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Heebo';
    src: url('../fonts/primary_font/Heebo-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Heebo';
    src: url('../fonts/primary_font/Heebo-Light.woff') format('woff');
    font-weight: lighter;
    font-style: normal;
}

@font-face {
    font-family: 'Heebo';
    src: url('../fonts/primary_font/Heebo-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Heebo';
    src: url('../fonts/primary_font/Heebo-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Heebo';
    src: url('../fonts/primary_font/Heebo-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Heebo';
    src: url('../fonts/primary_font/Heebo-Bold.woff') format('woff');
    font-weight: bolder;
    font-style: normal;
}

@font-face {
    font-family: 'Heebo';
    src: url('../fonts/primary_font/Heebo-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Heebo';
    src: url('../fonts/primary_font/Heebo-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

body {
    font-family: "Heebo", "Helvetica", sans-serif;
}
